import styled from "styled-components"

export const SectionSubtitle = styled.p`
  text-align: center;
  font-size: 0.8rem;
  margin: 20px 0 40px;
  font-weight: 100;
  @media only screen and (min-width: 1024px) {
    font-size: 1.1rem;

    margin: -30px 0 80px;
  }
`
