import { ArrowRightAlt } from "@material-ui/icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import styled from "styled-components"
import { SectionSubtitle } from "../components/SectionSubtitle/SectionSubtitle"
import { SectionTitle } from "../components/SectionTitle/SectionTitle"
import { useIntl } from "gatsby-plugin-intl"
const Section = styled.section`
  margin-top: 200px;
`

const PageNotFound = () => {
  const { locale } = useIntl()
  return (
    <Section>
      <SectionTitle style={{ fontSize: "9rem", letterSpacing: "-25px" }}>
        4
        <span
          role="img"
          aria-label="balloon"
          style={{ transform: "translate(15px, 25px)" }}
        >
          &#x1F388;
        </span>
        4
      </SectionTitle>
      <SectionSubtitle>Strona której szukasz nie istnieje</SectionSubtitle>
      <AniLink
        paintDrip
        duration={1}
        hex="#252a36"
        to={`/${locale}/`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "60px",
        }}
      >
        <ArrowRightAlt style={{ transform: "rotate(180deg)" }} />
        Powrót do strony głównej
      </AniLink>
    </Section>
  )
}

export default PageNotFound
