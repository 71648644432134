import React from "react"

import { Helmet } from "react-helmet"
import Navigation from "../components/Navigation/Navigation"
import Footer from "../views/Footer"
import PageNotFound from "../views/PageNotFound"

const Home = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "pl" }}>
        <meta charSet="utf-8" />
        <title>Strona nie odnaleziona</title>
      </Helmet>
      <Navigation />

      <PageNotFound />
      <Footer />
    </>
  )
}

export default Home
