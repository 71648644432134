import styled from "styled-components"

export const SectionTitle = styled.h2`
  font-size: ${({ small }) => (small ? "1.6rem" : "2rem")};
  margin: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-align: center;
  margin: 0 auto;

  @media only screen and (min-width: 1024px) {
    font-size: ${({ small }) => (small ? "2.7rem" : "3.3rem")};

    margin: 50px auto;
  }
`
